<template>
  <BaseDialog
    :open="open"
    width="385px"

    :accept-text="acceptText"
    :dismiss-text="dismissText || $t('ConfirmDialog.dismissText')"
    :accept-button-type="type"
    :close-on-click-modal="closeOnClickModal"

    @accept="handleAccept"
    @dismiss="handleDismiss"
  >
    <template #header>
      <h4
        class="tw-flex tw-items-center"
        :class="type === 'danger' ? 'tw-text-danger-500' : 'tw-text-primary-500'"
      >
        <component
          :is="titleIcon"
          v-if="titleIcon"
        />
        <span>{{ title || $t('ConfirmDialog.title') }}</span>
      </h4>
    </template>
    <template #content>
      <div
        class="tw-flex tw-justify-center tw-font-medium"
      >
        <p>{{ text }}</p>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    acceptText: {
      type: String,
      default: '',
    },
    dismissText: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'danger',
    },
  },
  emits: ['accept', 'dismiss'],
  methods: {
    handleAccept() {
      this.$emit('accept', this.userToAssignId);
    },
    handleDismiss() {
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('dismiss');
    },
  },
};
</script>
